.modal-content {
  row-gap: 15px;
  padding: 10px 0 15px;
}

.modal-header {
  padding: 0 15px;
  border-bottom: none;
}

.modal-title {
  margin: 0;
  font-size: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0;
}

.modal-footer {
  column-gap: 10px;
  padding: 0 15px;
  border-top: none;
}

.modal__display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 52px;
  background-color: $color-grey_light;
}

.modal__scores {
  font-weight: 700;
}

.modal__wrap-table {
  padding: 0 15px;
}

.modal.modal-static {
  transform: $modal-scale-transform;
  transition: scale .3s ease;
}