@keyframes change-opacity-in-end {
  0% {
    opacity: 0.9;
  }

  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}