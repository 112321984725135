.overlay {
  @include wrap-absolute;
  display: none;
  z-index: 10;
  background-color: $color-black;
  opacity: 0;
}

.overlay--visible {
  display: block;
}

.stop-scroll {
  overflow: hidden;
}