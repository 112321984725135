.burger {
  --animation-duration: .3s;

  display: block;

  &__btn {
    @include btn-reset;
    position: relative;
    width: 40px;
    height: 23px;
    z-index: 1010;

    &:hover {
      .burger__line {
        background-color: $color-primary_shade;
      }
    }
  }

  &__line {
    @include transition;
    transition-property: background-color;
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background-color: $color-primary;
    box-shadow: 1px 1px 1px 1px $color-primary_light;

    &:nth-child(1) {
      top: 0;
      animation: top-move-to-top var(--animation-duration) ease-in forwards;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(2) {
      animation: center-2-turn-to-burger var(--animation-duration) ease-in forwards;
    }

    &:nth-child(3) {
      animation: center-3-turn-to-burger var(--animation-duration) ease-in forwards;
    }

    &:nth-child(4) {
      bottom: 0;
      animation: bottom-move-to-bottom var(--animation-duration) ease-in forwards;
    }
  }
}

.burger--x-face {
  .burger__line:nth-child(1) {
    animation: top-move-to-center var(--animation-duration) ease-in forwards;
  }

  .burger__line:nth-child(4) {
    animation: bottom-move-to-center var(--animation-duration) ease-in forwards;
  }

  .burger__line:nth-child(2) {
    animation: center-2-turn-to-x-face var(--animation-duration) ease-in forwards;
  }

  .burger__line:nth-child(3) {
    animation: center-3-turn-to-x-face var(--animation-duration) ease-in forwards;
  }

}