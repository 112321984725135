@keyframes options-grow {
  0% {
    width: 39px;
    height: 0;
    transform: translateY(0);
  }

  40% {
    width: 39px;
    height: calc(var(--height) + 40px);
    transform: translateY(0);

  }

  60% {
    width: 39px;
    height: var(--height);
    transform: translateY(var(--distance));
  }

  100% {
    width: var(--width);
    height: var(--height);
    transform: translateY(var(--distance));
  }
}

@keyframes options-shrink {
  0% {
    width: 39px;
    height: 0;
    transform: translateY(calc(-1 * var(--distance)));
  }

  40% {
    width: 39px;
    height: calc(var(--height) + 40px);
    transform: translateY(calc(-1 * var(--distance)));
  }

  60% {
    width: 39px;
    height: var(--height);
    transform: translateY(0);
  }

  100% {
    width: var(--width);
    height: var(--height);
    transform: translateY(0);
  }
}