.options-element-caller {
  --top: '';
  --left: '';
}

.options-element-caller::before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 38px;
  height: 32px;
  border-radius: 5px;
  box-shadow: 0 0 10px var(--options-color-main);
  opacity: 0;
  z-index: 90;
}

.options-element-caller--throw-up::before {
  animation: options-element-caller-throw-up var(--throw-up-animation-duration) ease-out;
}

.options-element-caller--move-down::before {
  animation: options-element-caller-throw-up var(--throw-up-animation-duration) ease-in reverse;
}

@keyframes options-element-caller-throw-up {
  0% {
    top: var(--top);
    left: var(--left);
    opacity: 0;
  }

  3% {
    top: var(--top);
    left: var(--left);
    opacity: 1;
  }

  10% {
    background-color: var(--color-opacity);
  }

  20% {
    background-color: $color-option;
  }


  75% {
    top: 0;
    left: 0;
    height: 32px;
    border-radius: 5px;

  }

  85% {
    background-color: $color-option;
    height: 52px;
    border-radius: 0;
  }

  99% {
    top: 0;
    left: 0;
    height: 52px;
    border-radius: 0;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    background-color: $color-option;
    opacity: 0;
  }
}