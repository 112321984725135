.opacity-wave {
  animation: opacity-wave .6s ease-in-out;
}

@keyframes opacity-wave {
  0% {
    opacity: 0;
  }

  45% {
    opacity: .9;
  }

  55% {
    opacity: .9;
  }

  100% {
    opacity: 0;
  }
}