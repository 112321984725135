.nav {
  width: 100%;

  &__btn-close {
    display: none;
  }

  &__panel {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-top: 70px;
    padding-inline: 15px;
    width: 50%;
    max-width: 420px;
    min-width: 250px;
    height: 100vh;

    background-color: #fff;
    box-shadow: 0 1px 10px 1px $color-grey;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-120%);
    transition: visibility .3s ease, transform .3s ease-out, opacity .3s ease;
    z-index: 1000;

    .svg {
      fill: #333;
    }
  }

  &__panel--open {
    visibility: visible;
    opacity: 1;
    transform: translateX(0)
  }

  &__list {
    @include list-reset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 10px;
    padding-left: 10px;
  }

  &__link {
    @include link-reset;
    @include background;
    background-size: 16px;
    font-size: 18px;
    line-height: 100%;
    color: $color-black;
    outline: none;

    &:focus-visible {
      color: $color-primary_shade;

      .svg {
        fill: $color-primary_shade;
      }
    }

    &:hover {
      color: $color-primary;
      background-color: inherit;

      .svg {
        fill: $color-primary;
      }
    }

    &:active {
      color: $color-primary_shade;
      background-color: inherit;

      .svg {
        fill: $color-primary_shade;
      }
    }
  }

  &__link--calc {
    background-image: url('../images/icons/calculator.svg');
  }

  &__link--donate {
    padding-left: 22px;
    background-image: url('../images/icons/pink-donut.png');

    &:hover {
      color: #e16cca;
    }
  }

  &__title {
    @include title-reset;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }
}