// 100	Thin (Hairline)
// 200	Extra Light (Ultra Light)
// 300	Light
// 400	Normal (Regular)
// 500	Medium
// 600	Semi Bold (Demi Bold)
// 700	Bold
// 800	Extra Bold (Ultra Bold)
// 900	Black (Heavy)



@charset "UTF-8";

@font-face {
  font-display: swap;
  font-weight: 300;
  font-family: 'Geometria';
  font-style: normal;
  src: url(../fonts/Geometria-Light.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-weight: 400;
  font-family: 'Geometria';
  font-style: normal;
  src: url(../fonts/Geometria-Regular.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-weight: 500;
  font-family: 'Geometria';
  font-style: normal;
  src: url(../fonts/Geometria-Medium.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-weight: 700;
  font-family: 'Geometria';
  font-style: normal;
  src: url(../fonts/Geometria-Bold.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-weight: 900;
  font-family: 'Geometria';
  font-style: normal;
  src: url(../fonts/Geometria-ExtraBold.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-weight: 400;
  font-family: 'Circe';
  font-style: normal;
  src: url(../fonts/Circe-Regular.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-weight: 700;
  font-family: 'Circe';
  font-style: normal;
  src: url(../fonts/Circe-Bold.woff2) format("woff2")
}