$color-primary: #a65cf0;
$color-primary_light: #c998fb;
$color-primary_shade: #7033AC;

$color-secondary: #ff862f;
$color-secondary_light: #ffbd59;
$color-secondary_shade: #bb370e;

$color-pink_light: #f8efff;
$color-red_input: #ff6972;
$color-green_input: #b8ec64;

$color-grey: #999999;
$color-grey_light: #f6f6f6;
// $color-grey_alfa: rgba(246, 246, 246, 1);
$color-grey_alfa: rgba(100, 100, 100, 0.1);

$color-grey_shade: #666666;
$color-md_grey: #eaeaea;

$color-gold: #d5b986;
$color-bone: #fff5d1;

$color-black: #333;
$color-white: #fff;

$color-option: $color-primary;
$color-disabled: $color-md_grey;

$modal-fade-transform: none;
$modal-scale-transform: scale(1)