.table {
  width: 100%;
  border-width: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.tr {
  border-radius: 5px;
  font-size: 0;

  &__section {
    padding: 5px 0;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
  }

  &__section--edit {
    position: relative;
  }

  &__section--name {
    width: 99%;
  }

  // TODO при замене на .wrapper-modal-panel удалить здесь &__section--goe (tr__section--goe)
  &__section--goe {
    position: relative;
  }

  &__section--align-left {
    text-align: left;
  }

  &__wrapper {
    display: inline-block;
  }
}

.tr--empty {
  box-shadow: unset;

  .tr__section {
    padding: 5px 0;
  }
}

.table--calc {
  .thead {
    text-transform: lowercase;
  }

  .tbody {
    .tr {
      box-shadow: 0 0 4px $color-grey;
    }
  }

  .tr__section {
    padding-left: 5px;
    padding-right: 5px;


    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }
}

/* end calc */



.table--element {

  .thead {
    text-transform: lowercase;
    color: $color-grey;

    .tr__section {
      padding-top: 15px;
    }

    .tr__text {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -1px;
    }
  }

  .tr__section {
    width: auto;
    max-width: 38px;
  }

  .tbody {

    .tr {
      border-bottom: 2px solid $color-grey_light;
    }

    .tr__section {
      padding: 10px 1px;

      &:last-child {
        text-align: right;
      }
    }

    .tr__section--options {
      text-align: left;

      .tr__wrapper {
        display: flex;
        flex-direction: column;
      }
    }

    .button-options {
      border-color: $color-secondary;
      color: $color-secondary;
      filter: opacity(.5);

      &:not(:only-child) {
        height: 25px;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      & svg {
        width: 10px;
        fill: $color-secondary;
      }

      &:hover,
      &:active {
        background-color: $color-secondary;
        color: #fff;

        svg,
        svg {
          fill: #fff;
        }
      }
    }
  }

  .button {
    padding: 0;
    height: 32px;
    min-width: 38px;
  }

  .button--freeze {
    @include element-disabled;
  }

  .checkbox--checked {

    background-color: $color-option;
    color: #fff;

    &::before {
      content: none;
    }
  }
}

// end table-element

/* element */

/* guide-value */

.table--guide-value {

  .table__element-legend {
    font-weight: 500;
  }

  .button-options {
    height: 32px;
    width: 38px;
  }

  .tr__section--scores {
    margin-inline: 5px;
  }
}

/* end guide-value */

@media (max-width: 410px) {
  :is(.table--guide-value, .table--element) thead {
    .tr__section {
      font-size: 12px;
      letter-spacing: -1px;
    }
  }
}