.tr__section--edit {
  --width: 138px;
  --height: 127px;
  --display: flex;
  --space-top: 50px;
  --distance: var(--space-top);
}

// TODO заменить везде .tr__section--goe на .wrapper-modal-panel
.tr__section--goe {
  --width: 200px;
  --height: 116px;
  --display: grid;
  --space-top: 50px;
  --distance: var(--space-top);
}

.wrapper-modal-panel {
  --width: 200px;
  --height: 116px;
  --display: grid;
  --space-top: 45px;
  --distance: var(--space-top);

}

.table-calc-options--opening {
  animation: options-grow var(--open-options-animation-duration) ease-in-out forwards;
}

.table-calc-options--opening::before {
  @include background-colored;
  z-index: 10;
  animation: change-opacity-in-end var(--open-options-animation-duration) ease-in-out;
}

.table-calc-options--open {
  top: var(--space-top);
  bottom: var(--space-bottom);
  display: var(--display);
  padding: 0;
  width: var(--width);
  height: var(--height);
  border-radius: 5px;
  box-shadow: 5px 7px 10px 1px $color-grey;
  background-color: rgba(200, 200, 200, .7);

  z-index: 10;

  .checkbox--checked {
    background-color: $color-option;
    border-color: $color-option;
  }
}

:where(.table-calc-options--open) .checkbox {
  background-color: #fff;
}

.table-calc-options--closing {
  animation: options-shrink var(--open-options-animation-duration) ease-in-out forwards reverse;
}

.table-calc-options--closing::before {
  @include background-colored;
  animation: change-opacity-in-end var(--open-options-animation-duration) ease-in-out reverse;
}