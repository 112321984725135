.options {
  position: absolute;
  top: 0;
  display: none;
  flex-direction: column;
  gap: 5px;
  background-color: #fff;
  overflow: hidden;

  &__checkbox {
    @include border;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 5px 8px;
    height: 39px;
    color: $color-option;
    transition: background-color .2s ease-in, color .2s ease-in;

    &::before {
      content: none;
    }

    .svg {
      width: 25px;
      height: 36px;
      fill: $color-option;
    }

    &:hover,
    &:active {
      background-color: $color-option;
      color: #fff;

      .svg {
        fill: #fff;
      }
    }
  }

  .checkbox--checked {
    color: #fff;

    .svg {
      fill: #fff;
    }

    &::before {
      content: none;
    }
  }
}

// options

.options--element {
  --display: flex;
  left: 0;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  width: 0;
  min-height: 52px;

  background-color: $color-grey_light;
  text-transform: capitalize;

  .checkbox {
    display: flex;
    justify-content: center;
    height: 32px;
    min-width: 38px;
  }
}

.options--type {
  top: 5px;
  width: 39px;
  height: 0;
}

// TODO заменить везде options--goe на options--modal-panel
.options--goe {
  top: 5px;
  right: 5px;
  grid-template-columns: repeat(5, 32px);
  grid-template-rows: repeat(3, 32px);
  flex-direction: row;
  gap: 10px;
  width: 39px;
  height: 0;

  .checkbox {
    display: flex;
    justify-content: center;
    height: 32px;
    width: 32px;
  }

  .checkbox:nth-child(6) {
    grid-column: 1 / span 5;
    grid-row: 2/ 3;
    justify-self: center;
  }
}

.wrapper-modal-panel {
  position: relative;
  display: inline-block;
}


.options--modal-panel {
  top: 0;
  right: 0;

  gap: 10px;
  width: 39px;
  height: 0;

  .checkbox {
    display: flex;
    justify-content: center;
    height: 32px;
    width: 32px;
  }
}

.options--modal-panel-goe {
  grid-template-columns: repeat(5, 32px);
  grid-template-rows: repeat(3, 32px);

  .checkbox:nth-child(6) {
    grid-column: 1 / span 5;
    grid-row: 2/ 3;
    justify-self: center;
  }
}

.options--modal-panel-auto {
  grid-template-columns: repeat(auto-fit, minmax(32px, auto));
  align-items: center;
  justify-content: space-between;
}

.options--visible {
  display: var(--display);
  z-index: 100;
}

.options--active {
  display: flex;
  z-index: 2;
}

// .options--top {
//   top: 0;
// }

.options--bottom {
  --space-top: initial;
  --space-bottom: 45px;
  --distance: -45px;
  top: unset;
  bottom: 0;
}

.options--left {
  left: 0;
}

.options--right {
  right: 0;
}

.space-evenly {
  justify-content: space-evenly;
}