.panel-tabs {
  @include list-reset;

  display: flex;
  column-gap: 15px;
  margin-bottom: 30px;

  .checkbox--checked {
    background-color: inherit;
    color: $color-primary;
  }
}

.options--panel-tabs {

  .options__checkbox {
    border: none;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    font-size: 20px;
    color: black;
    transition: color .1s ease-in, border-bottom-color .1s ease-in;

    &:hover,
    &:active {
      background-color: inherit;
      color: $color-primary;
    }
  }

  .checkbox--checked {
    border-bottom-color: $color-primary_shade;
    color: $color-primary_shade;

    &:hover {
      border-bottom-color: $color-primary;
    }
  }
}

@media (max-width: 410px) {
  .options--panel-tabs {
    .options__checkbox {
      font-size: 18px;
    }
  }
}