@keyframes center-2-turn-to-x-face {
  0% {
    rotate: 0;
  }

  30% {
    rotate: 0;
  }

  33% {
    rotate: 0;
  }

  100% {
    rotate: 30deg;
    transform-origin: 53% 10%;
  }
}

@keyframes center-3-turn-to-x-face {
  0% {
    rotate: 0;
  }

  30% {
    rotate: 0;
  }

  33% {
    rotate: 0;
  }

  100% {
    rotate: -30deg;
  }
}

@keyframes top-move-to-center {
  0% {
    translate: 0 0;
    opacity: 1;
  }

  25% {
    translate: 0 9px;
    opacity: 1;
  }

  30% {
    translate: 0 9px;
    opacity: 0;
  }

  100% {
    translate: 0 9px;
    opacity: 0;
  }
}

@keyframes bottom-move-to-center {
  0% {
    translate: 0 0;
    opacity: 1;
  }

  25% {
    translate: 0 -9px;
    opacity: 0;
  }

  30% {
    translate: 0 -9px;
    opacity: 0;
  }

  100% {
    translate: 0 -9px;
    opacity: 0;
  }
}

//  для возвращения в положение "бургер"
@keyframes center-2-turn-to-burger {
  0% {
    rotate: 30deg;
    transform-origin: 53% 10%;
  }

  30% {
    rotate: 0;
  }

  33% {
    rotate: 0;
  }

  100% {
    rotate: 0;
  }
}

@keyframes center-3-turn-to-burger {
  0% {
    rotate: -30deg;
  }

  30% {
    rotate: 0;
  }

  33% {
    rotate: 0;
  }

  100% {
    rotate: 0;
  }
}

@keyframes top-move-to-top {
  0% {
    translate: 0 9px;
    opacity: 0;
  }

  70% {
    translate: 0 9px;
    opacity: 0;
  }

  75% {
    translate: 0 9px;
    opacity: 1;
  }

  100% {
    translate: 0 0;
    opacity: 1;
  }
}

@keyframes bottom-move-to-bottom {
  0% {
    translate: 0 -9px;
    opacity: 0;
  }

  70% {
    translate: 0 -9px;
    opacity: 0;
  }

  75% {
    translate: 0 -9px;
    opacity: 1;
  }

  100% {
    translate: 0 0;
    opacity: 1;
  }
}